import { React, useState } from 'react';
import './Home.css';
import axios from 'axios';
import { Button, message, Modal } from 'antd'
import NumericInput from './NumericInput'


function App() {
    const [messageApi, contextHolder] = message.useMessage();

    const [valueFolio, setValueFolio] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);


    const warningMessage = (message) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };
    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    const sendFolio = () => {

        if (valueFolio.length >= 6) {
            setButtonLoading(true)
            axios.post('https://api.tramiteadelanto.com/api/v2/adelanto/validate-folio', {
                folio: valueFolio
            }).then(function (response) {
                if (response.data.success == true) {
                    window.location.href = response.data.url
                } else {
                    if (response.data.message.includes("error")) {
                        warningMessage("Por el momento el servicio no está disponible, intente de nuevo mas tarde")
                    }
                    else {
                        warningMessage("El folio ingresado es inválido")
                    }
                }
            }).catch(function (error) {
                console.log(error);
            }).finally(function () {
                // siempre sera ejecutado
                setButtonLoading(false)
            });
            //window.location.href = "https://nvo.tramiteadelanto.com?f="+valueFolio
        }
        else {
            warningMessage("Debe introducir un folio válido")
        }
    }

    const redirectToUrl = () => {
        if (valueFolio.length >= 6) {
            window.location.href = "https://nvo.tramiteadelanto.com?f="+valueFolio
        }
        else {
            warningMessage("Debe introducir un folio válido")
        }
    }

    return (
        <div className="Home">
            {contextHolder}
            {/* <div className='bannerSuperior'>
                <div className='firstText'>Adelanto</div>
                <img className='logo' src="/biglogobn.png" alt="Logo Mujeres con Bienestar" /> 
                <div className='secondText'>Asistencia financiera para acceso al crédito</div>
            </div> */}
            <div className='bannerSuperiorDos'>
                <img className='logo' src="/hoja_l.png" alt="Logo" />
                <div className='firstText'>
                    ADELANTO CON FINANCIAMIENTO<br/>
                    <span className='subtitle'>Asistencia financiera para acceso al crédito</span>
                </div>
                <img className='logo' src="/hoja_r.png" alt="Logo" />
                {/* <img className='logo' src="/biglogobn.png" alt="Logo Mujeres con Bienestar" /> */}
            </div>
            <div className='generalContent'>
                <div className='contentSection'>
                    <div className='spetial1' >Información general del <br /><strong style={{ color: '#806c4b' }}>Adelanto con Financiamiento</strong></div>
                    <div className='spetial2' >Monto del crédito</div>
                    <div className='spetial3' ><span style={{ backgroundColor: '#e3d7c6', padding: 5 }}>$3,000.00 MXN</span></div>
                    <div className='spetial4' >El pago se descuenta de manera automática en su cuenta en las siguientes fechas:</div>
                    <div className='descuentos'>
                        {/* <div>Descuento Agosto de $2,201.73 MXN</div>
                        <div>Descuento Octubre de $2,201.73 MXN</div>
                        <div>Descuento Diciembre de $2,201.73 MXN</div> */}


                        <div className='descuentoBullet'><div className='mes'>Octubre</div><div className='cantidad'>$2,196 MXN</div></div>
                        <div className='descuentoBullet'><div className='mes'>Diciembre</div><div className='cantidad'>$2,196 MXN</div></div>
                        {/* <div className='descuentoBullet'><div className='mes'>Diciembre</div><div className='cantidad'>$2,238.82 MXN</div></div> */}
                    </div>
                </div>
                <div className='contentSection'>
                    <div style={{ fontSize: '1.7rem', textAlign: 'center', marginBottom: 20, fontWeight: 700 }}>Requisitos</div>
                    <div className='requisitos'>
                        <div className='bullet'>Ser beneficiaria del programa Mujeres con Bienestar, tener la aplicación y contar con la tarjeta activa</div>
                        <div className='bullet'>Tener entre 18 y 64 años</div>
                        <div className='bulletFirma'>
                            <div className='text'>Inicia tu trámite ingresando aquí el folio que recibiste por SMS</div>
                            <div className='formulario'>
                                <NumericInput
                                    value={valueFolio}
                                    onChange={setValueFolio}
                                />
                                <Button style={{ marginLeft: 10, backgroundColor: '#806c4b' }} type="primary" size='large' onClick={redirectToUrl} loading={buttonLoading} >
                                    Iniciar trámite
                                </Button>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='contentSection'>
                    <div>
                        <p style={{ fontSize: '1.1rem', textAlign: 'center' }}>
                            Después de firmar su solicitud digitalmente, el depósito se realizará a su tarjeta del Bienestar en un plazo de 24 a 72 horas hábiles. Es necesario que revise periódicamente su cuenta para validar su depósito.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: '1.1rem', textAlign: 'center' }}>
                            TRAMITE GRATUITO, POR TU SEGURIDAD NO SE SOLICITA INFORMACIÓN NI DOCUMENTACIÓN DE NINGUNA BENEFICIARIA. EL TRÁMITE SOLO SE REALIZA POR INVITACIÓN.
                        </p>
                    </div>
                </div>
            </div>

            
            <div className='modal-content' style={{display:popupOpen?'flex':'none'}}>
                <div className='modal'>
                    <div onClick={() => setPopupOpen(false)} className='closeButton'>
                        Cerrar
                    </div>
                    <div className='title'>
                        ¡Realiza tu trámite HOY!
                    </div>
                    <div className='content'>
                        La promoción termina el próximo<br/>Lunes 29 de Abril
                    </div>
                </div>
            </div>


        </div>
    );
}

export default App;
